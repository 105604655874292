import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
const getLang = () => {
  if (localStorage.getItem("lng")) return localStorage.getItem("lng");
  return "en";
};

let lng = getLang();
localStorage.setItem("lng", lng);
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lng || "en",
    // debug: true,
    fallbackLng: "en",
    keySeparator: ".",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/translations/{{lng}}/{{ns}}.json",
      allowMultiLoading: true,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
