import { CardMedia } from "@mui/material";
import { logo } from "../../assets";

const ImageWithPlaceholder = ({ img, height }) => {
  return (
    <CardMedia
      src={img}
      component={img ? "img" : undefined}
      sx={{
        ...(!img
          ? {
              backgroundColor: "lightgray",
              display: "grid",
              placeItems: "center",
              height,
            }
          : {
              objectFit: "cover",
              objectPosition: "center",
              height: height ?? "100%",
              width: "100%",
              borderRadius: "10px",
            }),
      }}
    >
      {!img ? <img src={logo} alt="" /> : undefined}
    </CardMedia>
  );
};

export default ImageWithPlaceholder;
