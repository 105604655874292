import { getRequest } from "../connection/network";

const notificationsAPI = {
  list: "notifications",
};

export const listNotificationsService = async ({ page, limit }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, allPages },
      },
    } = await getRequest({
      token,
      query: {
        page,
        limit,
      },
      endPoint: notificationsAPI.list,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
