import { SOCKETCONNECTED, START_SHAKING } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  sideList: "chats",
  date: "",
  event: "",
  task: "",
  socketStatus: "disconnected",
  private: false,
  drawerOpen: false,
  shakingChat: null,
};

const global = createReducer(initialState, (builder) => {
  builder
    .addCase(SOCKETCONNECTED, (state, { payload }) => {
      state.socketStatus = payload;
    })

    .addCase(START_SHAKING, (state, { payload }) => {
      state.shakingChat = payload;
    });
});

export default global;
