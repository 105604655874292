import {
  BILLS_LOADING,
  BILLS_ERROR,
  GET_BILLS,
  CREATE_BILL,
  UPDATE_BILL,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const billsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BILLS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(BILLS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_BILLS, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_BILL, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(UPDATE_BILL, (state, { payload }) => {
      const updatedData = payload?.bill;
      const updatedIndex = state.data.bill.findIndex(
        (bill) => bill.id === updatedData.id
      );
      state.data.bill[updatedIndex] = {
        ...state.data.bill[updatedIndex],
        ...updatedData,
      };
    });
});

export default billsReducer;
