import {
  postRequest,
  getRequest,
  putRequest,
  delRequest,
} from "../connection/network";
const authApi = {
  check: "auth/check",
  register: "/auth/register",
  updateProfile: "user/profile",
  contact: "user/contacts",
  resetPassword: {
    request: "auth/reset-password/request",
    verify: "auth/reset-password/verify",
    reset: "auth/reset-password/reset",
  },
  subsribe: "auth/subscribe",
};

export const sendPasswordRequestService = async ({ body, cb }) => {
  try {
    const response = await putRequest({
      body: { ...body },
      endPoint: authApi.resetPassword.request,
    });

    cb && cb(null);
    return { response };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    cb && cb(sError);
    return { error: sError };
  }
};

export const verifyPasswordResetService = async ({ body, cb }) => {
  try {
    const response = await putRequest({
      body: { ...body },
      endPoint: authApi.resetPassword.verify,
    });

    cb && cb(null);
    return { response };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    cb && cb(sError);
    return { error: sError };
  }
};

export const resetPasswordService = async ({ body, cb }) => {
  try {
    const response = await putRequest({
      body: { ...body },
      endPoint: authApi.resetPassword.reset,
    });

    cb && cb(null);
    return { response };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    cb && cb(sError);
    return { error: sError };
  }
};

export const checkExistsService = async ({ mobile, email, username }) => {
  try {
    await getRequest({
      query: {
        mobile,
        email,
        username,
      },
      endPoint: authApi.check,
    });
    return { success: true };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    return { success: false, error: sError };
  }
};

export const registerService = async ({ sendData }) => {
  try {
    // const sendFormData = new FormData();
    // for (const [key, value] of Object.entries(sendData)) {
    //   value && sendFormData.append(key, value);
    // }
    let {
      data: { data },
    } = await postRequest({ body: sendData, endPoint: authApi.register });

    return { data };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    return { error: sError };
  }
};

export const updateProfileService = async ({ body, cb }) => {
  const token = localStorage.getItem("token");
  try {
    let {
      data: { data },
    } = await putRequest({
      body,
      endPoint: authApi.updateProfile,
      token,
      isFormData: true,
    });

    cb && cb(null);
    return { data };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    cb && cb(sError);
    return { error: sError };
  }
};

export const deleteUserContactSerivce = async ({ contactIds }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data, message },
    } = await delRequest({
      endPoint: authApi.contact,
      token,
      query: {
        contactIds,
      },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unkown error occurred";
    return { error: sError };
  }
};

export const subscribeDeviceTokenService = async (deviceToken) => {
  if (deviceToken) {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await getRequest({
          query: { deviceToken },
          endPoint: authApi.subsribe,
          token,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
