import store from "../..";
import {
  createOfferService,
  listOffersService,
} from "../../../services/offers";

import {
  CREATE_OFFER,
  GET_OFFERS,
  GET_PROGRESS_OFFERS,
  GET_REVIEW_OFFERS,
  OFFERS_ERROR,
  OFFERS_LOADING,
  PROGRESS_OFFERS_ERROR,
  PROGRESS_OFFERS_LOADING,
  REVIEW_OFFERS_ERROR,
  REVIEW_OFFERS_LOADING,
} from "../types";

export const getOffersAction =
  ({ page, limit, next, requestId, status }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.requests;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: OFFERS_LOADING });

    return listOffersService({
      page,
      limit,
      requestId,
      status,
    })
      .catch((e) =>
        dispatch({
          type: OFFERS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: OFFERS_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_OFFERS,
          payload: d,
        });
      });
  };

export const createOfferAction =
  ({ sendData, cb = () => {} }) =>
  (dispatch) => {
    return createOfferService({ sendData }).then((res) => {
      const { error, data } = res;
      if (error || data?.error) return cb(error || data?.error);
      dispatch({ type: CREATE_OFFER, payload: data });
      cb && cb(null);
    });
  };

export const getReviewOffersAction =
  ({ page, limit, next, status }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.requests;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: REVIEW_OFFERS_LOADING });

    return listOffersService({
      page,
      limit,
      status,
    })
      .catch((e) =>
        dispatch({
          type: REVIEW_OFFERS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: REVIEW_OFFERS_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_REVIEW_OFFERS,
          payload: d,
        });
      });
  };

  export const getInProgressOffersAction =
  ({ page, limit, next, status }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.requests;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: PROGRESS_OFFERS_LOADING });

    return listOffersService({
      page,
      limit,
      status,
    })
      .catch((e) =>
        dispatch({
          type: PROGRESS_OFFERS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: PROGRESS_OFFERS_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_PROGRESS_OFFERS,
          payload: d,
        });
      });
  };