import { Box, Typography } from "@mui/material";
import SingleAnimation from "../Animations/SingleAnimation";
import { useTranslation } from "react-i18next";

export default function HomeTitle({ subtitle, title, inView }) {
  const {
    i18n: { language: lang },
  } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "1em",
        px: 1,
      }}
    >
      <SingleAnimation delay={250} left inView={inView}>
        <Typography
          variant="subtitle1"
          sx={{ color: "#000", fontWeight: "600" }}
        >
          {subtitle[lang]}
        </Typography>
      </SingleAnimation>
      <Box sx={{ display: { xs: "none", sm: "flex" } }}>
        <SingleAnimation delay={500} left inView={inView}>
          <Typography variant="h4" sx={{ color: "#191825", fontWeight: "600" }}>
            {title[lang]}
          </Typography>
        </SingleAnimation>
      </Box>
      <Box sx={{ display: { xs: "flex", sm: "none" } }}>
        <SingleAnimation delay={500} left inView={inView}>
          <Typography variant="h6" sx={{ color: "#191825", fontWeight: "600" }}>
            {title[lang]}
          </Typography>
        </SingleAnimation>
      </Box>
    </Box>
  );
}
