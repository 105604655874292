import { createSlice } from "@reduxjs/toolkit";

const chatMediaSlice = createSlice({
  name: "chatMedia",
  initialState: {
    data: [],
    allPages: 0,
    thisPage: 0,
    moreLoading: false,
    loading: false,
    error: "",
    count: 0,
  },
  reducers: {
    getMediasLoading: (state) => {
      state.loading = true;
    },
    getMediasMoreLoading: (state) => {
      state.moreLoading = true;
    },
    getMedias: {
      reducer: (state, { payload }) => {
        const { thisPage, allPages, data, count } = payload;

        state.data = thisPage === 1 ? data : [...state?.data, ...data];
        state.thisPage = thisPage;
        state.loading = false;
        state.moreLoading = false;
        state.allPages = allPages;
        state.count = count;
      },
    },
  },
});
export { chatMediaSlice };
