import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMemo, useState } from "react";
import TextInput from "../components/TextInput";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { MdClose } from "react-icons/md";
import { createProjectAction } from "../../../store/actions/projects";
import { useDispatch } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import Loader from "../../../Components/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1,
  maxWidth: "686px",
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 2,
  p: 4,
  maxHeight: "75vh",
  overflow: "auto",
  borderRadius: "10px",
  border: 0,
};

export const AddForm = ({ open, closeAdd }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["booking", "auth"]);
  const [loading, setLoading] = useState();
  const [images, setImages] = useState([]);

  const uploadPhoto = (e) => {
    if (images?.length > 6) return;
    let files = [...e.target.files];
    files = files?.map((image) => {
      return {
        ...image,
        media: URL.createObjectURL(image),
        id: uuid(),
        file: image,
      };
    });

    setImages([...images, ...files]);
  };
  const deletePhoto = (imageId) => {
    setImages((prev) => prev.filter((image) => image.id !== imageId));
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string(t("auth:project_name"))
          .min(3, t("auth:too_short"))
          .max(50, t("auth:too_long"))
          .required(t("auth:project_name_required")),
        email: yup.string(t("auth:enter_email")).email(t("auth:enter_email")),
        mobile: yup
          .string()
          .min(10, t("auth:too_short"))
          .max(10, t("auth:too_long"))
          .required(t("projects.phone_required")),
        location: yup
          .string()
          .url(t("auth:must_be_url"))
          .max(5000)
          .required(t("auth:location_required")),
      }),
    [t]
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      location: "",
      email: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);
      const sendData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        value && sendData.append(key, value);
      }
      if (images?.length > 0) {
        images?.map((file) => sendData.append("image", file.file));
      }

      createProjectAction({
        sendData,
        cb: (err) => {
          if (!err) {
            closeAdd();
            formik.resetForm();
          }
          setLoading(false);
        },
      })(dispatch);
    },
  });

  return (
    <>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loading}
      >
        <Loader
          height={"50px"}
          style={{ filter: "brightness(0%) invert(100%)" }}
        />
      </Backdrop>
      <Modal
        open={open}
        onClose={closeAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper sx={style} height={1} width={1}>
          <IconButton
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              color: "text.primary",
            }}
            onClick={closeAdd}
            size="small"
          >
            <MdClose size={24} />
          </IconButton>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Typography
              textAlign="center"
              variant="h6"
              fontWeight={600}
              sx={{ mb: 4, mt: 2 }}
            >
              {t("add_new_project")}
            </Typography>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={`${t("projects.name")}*`}
                  placeholder={t("projects.name_placeholder")}
                  name="name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={`${t("projects.location")}*`}
                  placeholder={t("projects.location_placeholder")}
                  name="location"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={`${t("projects.phone")}*`}
                  placeholder={t("projects.phone_placeholder")}
                  name="mobile"
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextInput
                  label={t("projects.email")}
                  placeholder={t("projects.email_placeholder")}
                  name="email"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("projects.details")}
                  placeholder={t("projects.details_placeholder")}
                  name="description"
                  formik={formik}
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: 1,
                    maxWidth: "713px",
                  }}
                >
                  <Input
                    id="contained-button-file"
                    inputProps={{
                      accept: "image/jpeg, image/png, image/gif",
                      multiple: true,
                    }}
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadPhoto}
                    error={formik.touched.image && Boolean(formik.errors.image)}
                  />
                  <Box
                    sx={{
                      width: 1,
                      height: "163px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {(images?.length < 6 || !images?.length) && (
                      <label
                        htmlFor="contained-button-file"
                        style={{
                          width: "100%",
                          flexGrow: 1,
                          height: "100%",
                          maxWidth: images?.length > 0 && "200px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 1,
                            borderRadius: "6px",
                            backgroundColor: "background.secondary",
                            width: 1,
                          }}
                        >
                          <CloudUploadOutlinedIcon
                            sx={{ color: "text.secondary" }}
                          />
                          <Typography color="text.secondary" textAlign="center">
                            {t("projects.image")}
                          </Typography>
                        </Box>
                      </label>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "scroll",
                        height: 1,
                        flexGrow: 1,
                      }}
                    >
                      {images?.length > 0 &&
                        images?.map((image) => (
                          <Box
                            sx={{
                              position: "relative",
                              minWidth: "120px",
                              width: "120px",
                              height: "163px",
                              overflow: "hidden",
                              mr: 2,
                              borderRadius: "6px",
                              border: "1px solid",
                              borderColor: "text.secondary",
                            }}
                          >
                            <IconButton
                              sx={{
                                position: "absolute",
                                zIndex: "2",
                                right: 5,
                                color: "white",
                              }}
                              onClick={() => deletePhoto(image?.id)}
                            >
                              <CancelOutlinedIcon />
                            </IconButton>
                            <img
                              src={image?.media}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                              alt=""
                            />
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
                {formik.errors.image && (
                  <Box width={1} textAlign="center">
                    <Typography
                      variant="caption"
                      textALign="center"
                      color="secondary"
                      width={1}
                    >
                      {formik.errors.image}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mt: 4, width: 1, maxWidth: "182px", py: 1 }}
                disabled={loading}
              >
                {t("panel.done")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Modal>
    </>
  );
};
