import { useSpring, animated } from "react-spring";

export default function SingleAnimation({
  children,
  delay,
  style,
  top = false,
  right = false,
  bottom = false,
  left = false,
  inView,
}) {
  let transformValue = "";

  switch (true) {
    case top:
      transformValue = inView ? "translateY(0)" : "translateY(-100%)";
      break;
    case right:
      transformValue = inView ? "translateX(0)" : "translateX(100%)";
      break;
    case bottom:
      transformValue = inView ? "translateY(0)" : "translateY(100%)";
      break;
    case left:
      transformValue = inView ? "translateX(0)" : "translateX(-100%)";
      break;
    default:
      break;
  }

  const singleAnimation = useSpring({
    transform: transformValue,
    opacity: inView ? 1 : 0,
    config: { mass: 1, tension: 120, friction: 14 },
    delay: delay,
  });

  return (
    <animated.div style={{ ...style, ...singleAnimation }}>
      {children}
    </animated.div>
  );
}
