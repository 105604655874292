import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImageWithOverlappedCard from "../../../../Components/ImageWithOverlappedCard";
import { getRequestsAction } from "../../../../store/actions/requests";
import { AddForm } from "../../../AddRequest/AddProject/AddForm";

export default function NewRequests() {
  const { t } = useTranslation(["global", "booking"]);

  const dispatch = useDispatch();

  const requests = useSelector((s) => s?.requests?.data);
  const loading = useSelector((s) => s?.requests?.loading);

  const [addProjectOpen, setAddProjectOpen] = useState(false);

  const openAddProject = useCallback(() => setAddProjectOpen(true), []);
  const closeAddProject = useCallback(() => setAddProjectOpen(false), []);

  const getRequests = useCallback(
    ({ page, next }) => {
      dispatch && getRequestsAction({ page, limit: 6, next })(dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    getRequests && getRequests({ page: 1 });
  }, [getRequests]);

  const handleSeeMore = () => {
    getRequests({ next: true });
  };

  return (
    //<Box id="newRequestsSection">
    <Box id="NewRequests">
      <Typography
        variant="h4"
        sx={{
          color: "#191825",
          p: { xs: "1em", md: "1em" },
          fontWeight: 600,
          display: { xs: "none", sm: "block" },
        }}
      >
        {t("new_requests")}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "#191825",
          p: { xs: "1em", md: "1em" },
          fontWeight: 600,
          display: { xs: "block", sm: "none" },
        }}
      >
        {t("new_requests")}
      </Typography>

      <ImageWithOverlappedCard
        data={requests}
        loading={loading}
        hasTitle={false}
        hasButton={false}
        emptyTitle={t("no_requests_title")}
        emptySubtitle={t("no_requests_subtitle")}
        linkUrl={"request"}
      />

      {requests.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "2em" }}>
          <Button
            variant="text"
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleSeeMore}
          >
            <Typography variant="body1" sx={{ color: "#615F5F" }}>
              {t("see_more")}
            </Typography>
            <KeyboardArrowDown sx={{ color: "#615F5F" }} />
          </Button>
        </Box>
      )}
    </Box>
  );
}
