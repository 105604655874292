import grey from "@mui/material/colors/grey";

export const dark = ({ primColor, secColor }) => ({
  primary: {
    main: "#10DADA",
    background: "#202225",
    default: "#fff",
  },
  secondary: {
    main: secColor || "#ea5069",
    background: "#202225",
    default: "#fff",
  },
  divider: grey[500],
  background: {
    default: "#121212",
    paper: "#202225",
    menu: "#3b3d3f",
    secondary: "#2B2D30",
  },
  text: {
    primary: "#fff",
    secondary: grey[500],
    border: "#202225",
  },
});

export const darkBaseline = {
  scrollbarColor: "#6b6b6b #202225",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#202225",
    width: 1,
    height: 8,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#6b6b6b",
    minHeight: 8,
    border: "3px solid #202225",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#202225",
  },
};
