import { Box } from "@mui/material";
import Loader from "../Loader";

const PageLoader = ({ sx }) => {
  return (
    <Box
      sx={{ height: "90vh", ...sx }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      <Loader />
    </Box>
  );
};

export default PageLoader;
