export const storeUsersToLO = async (usersByIds) => {
  try {
    let currentUsers = localStorage.getItem("users");
    if (!currentUsers) {
      currentUsers = { byId: {} };
    } else {
      currentUsers = JSON.parse(currentUsers);
    }

    localStorage.setItem(
      "users",
      JSON.stringify({
        byId: { ...currentUsers.byId, ...usersByIds },
      })
    );
  } catch (error) {
    localStorage.setItem(
      "users",
      JSON.stringify({
        byId: { ...usersByIds },
      })
    );
  }
};

export const fetchUsers = (usersById = []) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }
  let currentToReturn = {};

  usersById = usersById.filter((u) => u);
  usersById.map((u) => (currentToReturn[u.id] = u));

  for (let v in currentToReturn || {}) {
    currentToReturn[v] = currentUsers?.byId[v];
  }

  return currentToReturn;
};

export const fetchSingleUser = (userId) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }
  return currentUsers["byId"][userId];
};

export const changeUserDataLO = (userId, newData = {}) => {
  let currentUsers = localStorage.getItem("users");
  if (!currentUsers) {
    currentUsers = { byId: {} };
  } else {
    currentUsers = JSON.parse(currentUsers);
  }

  let user = currentUsers.byId[userId];

  if (user) {
    currentUsers.byId[userId] = { ...currentUsers.byId[userId], ...newData };
  }

  localStorage.setItem(
    "users",
    JSON.stringify({
      byId: { ...currentUsers.byId },
    })
  );
};
