import moment from "moment";

export const getDate = (date, appLang = "en-us") => {
  if (!date) return "";
  const dateFormatted = moment(date).locale(appLang).format("MMM DD h:mm A");

  const formNow = moment(date).locale(appLang).format("h:mm A");

  let checkIfToday = moment(date).isSame(moment(), "day");
  if (checkIfToday) {
    return formNow;
  } else {
    return dateFormatted;
  }
};
