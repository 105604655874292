import grey from "@mui/material/colors/grey";
import { dark, darkBaseline } from "./dark";
import { light, lightBaseline } from "./light";

const getDesignTokens = ({ mode }) => {
  return {
    typography: {
      allVariants: {
        fontFamily: "Poppins",
        textTransform: "none",
      },
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            ...light(),
          }
        : {
            ...dark(),
          }),
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            height: 0,
          },
        },
      },
      MuiTextareaAutosize: {
        styleOverrides: { root: { background: "#202225" } },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: "Poppins",
            ...(mode === "light" ? lightBaseline : darkBaseline),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: mode === "light" ? "1px solid" : 0,
            borderColor: mode === "light" ? grey[200] : grey[800],
            fontFamily: "Poppins",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: 0,
            borderColor: mode === "light" ? grey[200] : grey[800],
            fontFamily: "Poppins",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            borderRadius: "4px",
          },
        },
      },
    },
  };
};

export default getDesignTokens;
