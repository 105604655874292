import { Box, Typography } from "@mui/material";

export default function EmptyData({ img, title, subtitle }) {
  return (
    <Box
      sx={{
        bgcolor: "#F9F9F9",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
        p: "2em",
        borderRadius: "16px",
      }}
    >
      <img src={img} alt="" style={{ maxHeight: "200px" }} />
      <Typography variant="h6" sx={{ color: "#615F5F", fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "#615F5F" }}>
        {subtitle}
      </Typography>
    </Box>
  );
}
