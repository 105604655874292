import { getRequest, postRequest } from "../connection/network";

const projectApi = "project";

export const listProjectsService = async ({ page, limit }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, nextPage },
      },
    } = await getRequest({
      token,
      query: { page, limit },
      endPoint: projectApi,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      nextPage: parseInt(nextPage),
      hasMore: data?.length === limit,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const createProjectService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({
      body: sendData,
      endPoint: projectApi,
      token,
      isFormData: true,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const listSingleProjectService = async ({ projectId }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await getRequest({
      token,
      endPoint: projectApi,
      params: projectId,
    });

    return {
      data,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};
