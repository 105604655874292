import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageLoader from "../Components/PageLoader";
import Main from "./Main";

const Registration = lazy(() => import("../Pages/Auth/Registeration"));
const UserLogin = lazy(() => import("../Pages/Auth/Login"));
const ForgotPassword = lazy(() => import("../Pages/Auth/ForgotPassword"));
const Verification = lazy(() => import("../Pages/Auth/Verification"));
const ResetPassword = lazy(() => import("../Pages/Auth/ResetPassword"));

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Suspense fallback={<PageLoader />}>
            <Main />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={<PageLoader />}>
            <Registration />
          </Suspense>
        }
      />

      <Route
        path="login"
        element={
          <Suspense fallback={<PageLoader />}>
            <UserLogin />
          </Suspense>
        }
      />

      <Route
        path="forgot-password"
        element={
          <Suspense fallback={<PageLoader />}>
            <ForgotPassword />
          </Suspense>
        }
      />

      <Route
        path="verification"
        element={
          <Suspense fallback={<PageLoader />}>
            <Verification />
          </Suspense>
        }
      />

      <Route
        path="reset-password"
        element={
          <Suspense fallback={<PageLoader />}>
            <ResetPassword />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
