import { configureStore } from "@reduxjs/toolkit";
import { chatMediaSlice } from "./reducers/chatMediaSlice";
import {
  auth,
  chats,
  singleChat,
  global,
  contacts,
  users,
  notifications,
  app,
  projects,
  requests,
  offers,
  bills,
  categories,
} from "./reducers";

const store = configureStore({
  reducer: {
    auth,
    chats,
    singleChat,
    global,
    contacts,
    users,
    notifications,
    app,
    projects,
    requests,
    offers,
    bills,
    categories,
    [chatMediaSlice.name]: chatMediaSlice.reducer,
  },
  //   middleware: (getDefaultMiddleware) => {
  //     return getDefaultMiddleware().concat(thunkMiddleware);
  //   },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
