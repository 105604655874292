import { getRequest, postRequest, putRequest } from "../connection/network";

const offerApi = "offer";
const updateStatusApi = "offer/status";

export const listOffersService = async ({ page, limit, requestId, status }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, nextPage, count },
      },
    } = await getRequest({
      token,
      query: { page, limit, requestId, status },
      endPoint: offerApi,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      nextPage: parseInt(nextPage),
      hasMore: data?.length === limit,
      count,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const createOfferService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({
      body: sendData,
      endPoint: offerApi,
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const updateOfferService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({
      body: sendData,
      endPoint: updateStatusApi,
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};
